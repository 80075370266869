import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import Vant from 'vant';
import api from './api/request';
import http from './api/shopping';
import VueCanvasPoster from 'vue-canvas-poster'
import Confirm from '@/views/confirm/index'
import Utils from './Utils'
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import { uniUsersAlt,uniImage,uniAsterisk,uniHouseUserSolid,uniArrowLeft } from 'vue-unicons/dist/icons'
Vue.prototype.$api = api
Vue.prototype.$http = http
Vue.prototype.$Utils = Utils
Vue.use(VueCanvasPoster)
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)
Vue.use(VueAwesomeSwiper)
Unicon.add([uniUsersAlt,uniImage,uniAsterisk,uniHouseUserSolid,uniArrowLeft])
Vue.use(Unicon )
import waterfall from "vue-waterfall2";
Vue.use(waterfall);
Vue.use(Vant);
Vue.config.productionTip = false
import flex from '@/config/directive'
Vue.prototype.$Confirm = Confirm;
new Vue({
  router,
  flex,
  store,
  render: h => h(App)
}).$mount('#app')
