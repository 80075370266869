<template>
  <!-- <div id="app" ref="app" :style="{ height: height + 'px' }"> -->
    <div id="app" ref="app" :style="{ height: 'auto' }">
    <keep-alive include="userLists"><router-view /></keep-alive>

  </div>
</template>
<script>
export default {
  name: 'app',
  data() {
    return {
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    }
  },
  created() {

  },
  mounted() {
    window.addEventListener('resize', this.getHeight, false);
  },
  beforeDestroy() {
    window.addEventListener('resize', this.getHeight, false);
  },
  methods: {
    getHeight() {
      this.height = window.innerHeight || document.documentElement.innerHeight || document.body.clientHeight;
    },
  }
}
</script>
<style lang="scss">
@import './common/font/font.css';

body {
  background-color: #F5F6F8;
  margin: 0;
  padding: 0;
  // font-family: PingFangSCBold !important;
  color: #000;
}

p {
  margin: 0;
  padding: 0;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#app {
  margin: 0;
  padding: 0;
}
</style>
