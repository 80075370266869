import http from './http'

export default {
    getConfig(param) {
        return http.get('front/enterprise/getJsapiTicket?url=' + param.url)
    },
    getAgentConfig(param) {
        return http.get('front/enterprise/getAgentConfigTicket?url=' + param.url)
    },
    getAuthorize(param) {
        return http.post('front/enterprise/getRedirectUri', param)
    },
    gettoken(param) {
        return http.get('front/enterprise/getStaffInfo?code=' + param.code)
    },
    getStaffList(param) {
        return http.get('front/enterprise/getStaffList?id=' + param.id)
    },
    // 获取重定向网页授权路径
    getRedirectUri(url) {
        return http.get(`front/enterpriseStaff/getRedirectUri?url=${url.url}`)
    },
     //获取员工多重身份
     getStaffLists(id) {
        return http.get(`front/enterpriseStaff/getStaffList?id=${id}`)
    },
      //获取员工标识
      getStaffInfo(code) {
        return http.post(`front/enterpriseStaff/getStaffInfo`, code)
    },
}