import http from './http'
export default {
    // *****
    // ********************************************************** 商城 ********************************************************************
    // 获取商品分类
    getclassify(param) {
        return http.get(`front/enterprise/cateList?id=${param}`)
    },
    // 获取商品列表
    getgoodlist(param) {
        return http.get(`front/enterprise/goodsList?parentId=${param.parentId}&cateId=${param.cateId}&limit=${param.limit}&page=${param.page}&searchValue=${param.searchValue}`)
    },
    // 获取微单页分类
    wCateList(param) {
        return http.get(`front/enterprise/wCateList?id=${param}`)
    },
    // 获取微单页列表
    getweidanyeList(param) {
        return http.get(`front/enterprise/weidanyeList?parentId=${param.parentId}&cateId=${param.cateId}&limit=${param.limit}&page=${param.page}&searchValue=${param.searchValue}`)
    },
    // 新闻分类
    acticleCate() {
        return http.get('front/enterprise/acticleCateList')
    },
    // 获取新闻列表
    acticleCateList(param) {
        return http.get(`front/enterprise/acticleList?cateId=${param.cateId}&limit=${param.limit}&page=${param.page}&searchValue=${param.searchValue}`)
    },
    // 视频分类
    videoCateList() {
        return http.get('front/enterprise/videoCateList')
    },
    // 获取视频列表
    getvideoCateList(param) {
        return http.get(`front/enterprise/videoList?parentId=${param.parentId}&cateId=${param.cateId}&limit=${param.limit}&page=${param.page}&searchValue=${param.searchValue}`)
    },
    // 获取收藏列表
    collocationList(param) {
        return http.get(`front/enterprise/collocationList?type=${param.type}&staff_info_id=${param.staff_info_id}&limit=${param.limit}&page=${param.page}&searchValue=${param.searchValue}`)
    },
    // 获取员工名片
    getMp(param) {
        return http.get('front/enterprise/getMp?staffInfoId=' + param)
    },
    // 获取员工名片详情
    card_info(param) {
        return http.get('front/enterprise/card_info?staff_info_id=' + param)
    },
    // *****
    // ************************************************************* 酷家乐****************************************************************
    // 方案列表
    getfaList(data) {
        let param = {
            'tagIds': data.tagIds,
            'searchValue': data.searchValue,
            'stype': data.stype,
            'type': data.type,
            'page': data.page,
            'limit': data.limit,

        }
        return http.post('front/enterprise/local_ls', param)
    },
    // 方案标签
    faTagList(data) {
        return http.get('front/enterprise/local_tag_ls')
    },
    // 实景图
    gallery(data) {
        let param = {
            searchValue: data.galleryNameL,
            limit: data.num,
            page: data.start
        }
        return http.get(`front/enterprise/local_gallery?page=${param.page}&limit=${param.limit}&searchValue=${param.searchValue}`)
    },
    // 我的方案
    getMy_pro(data) {
        let id = JSON.parse(sessionStorage.getItem("staff")).id
        //  2066
        return http.get(`front/enterprise/my_pro?start=${data.start}&limit=${data.limit}&uid=${id}&keyword=${data.keyword}`)
    },
    // *****
    // ************************************************************* 员工*******************************************************************
    // 员工列表
    getstafflist(data) {
        return http.post('front/enterpriseStaff/staffLst', data)
    },
    // 添加员工
    addstaff(data) {
        return http.post('front/enterpriseStaff/addStaff', data)
    },
    // 删除员工
    delstaff(data) {
        return http.get('front/enterpriseStaff/delStaff?staffId=' + data)
    },
    // 编辑员工
    updateStaff(data) {
        return http.post('front/enterpriseStaff/updateStaff', data)
    },
    // 查询订单
    getOrder(data) {
        return http.get('front/enterprise/getOrder?searchValue=' + data.searchValue + "&userId=" + data.userId + '& staff_info_id=' + data.staff_info_id)
    },
    // *****************************************  ecrm   *********************************************
    //   待办
    staffInfo_Sum(data) {
        return http.get('front/enterpriseStatistics/staffInfo', data)
    },
    // 客户来源分析
    customComeStatistics(data) {
        return http.post('front/enterpriseStatistics/customComeStatistics', data)
    },
    // 客户总览
    customSum(data) {
        return http.post('front/enterpriseStatistics/customStatistics', data)
    },
    // 业绩总览
    performanceStatistics(data) {
        return http.post('front/enterpriseStatistics/performanceStatistics', data)
    },
    // 当前客户数据
    customStstus(data) {
        return http.post('front/enterpriseStatistics/customStstus', data)
    },
    // 线索总览
    clueStatistics(data) {
        return http.post('front/enterpriseStatistics/clueStatistics', data)
    },
    // 线索总览=>转化率
    conversionRate(data) {
        return http.post('front/enterpriseStatistics/conversionRate', data)
    },
    // 漏斗图 意向客户  成交数
    invertedTriangleStatistics(data) {
        return http.post('front/enterpriseStatistics/invertedTriangleStatistics', data)
    },
    // 转发统计
    forwardStatistics(data) {
        return http.post('front/enterpriseStatistics/forwardStatistics', data)
    },
    // 企业微信分享数据埋点、
    createStatisticsLog(data) {
        return http.post('job/createStatisticsLog', data)
    },
    // 获取节点处理人列表
    getNodeUser(data) {
        return http.post('front/enterprise/getNodeUser', data)
    },
    // 更换节点处理人
    changeHouseAssignee(data) {
        return http.post('front/enterpriseVtwo/changeHouseAssignee', data)
    },
    // 获取下一步节点人员
    getNextNodeUser(data) {
        return http.post('front/enterprise/getNextNodeUser', data)
    },
    // 下一步
    houseNext(data) {
        return http.post('front/enterpriseVtwo/houseNext', data)
    },
    //  下一步 默认自己
    houseNextSelf(data) {
        return http.post('front/enterpriseVtwo/houseNextSelf', data)
    },
    // 上一步
    houseRollback(data) {
        return http.post('front/enterpriseVtwo/houseRollback', data)
    },
    // 房屋状态列表
    houseStatusList(data) {
        return http.post('front/enterpriseVtwo/houseStatusList', data)
    },
    // 房屋丢失
    houseLose(data) {
        return http.post('front/enterpriseVtwo/houseLose', data)
    },
    //   crm待办客户列表
    todoCustomers(data) {
        return http.get('front/enterpriseVtwo/todoCustomers?staffInfoId=' + data.staffInfoId + '&page=' + data.page,)
    },
    //  crm房屋操作列表
    getElementList(data) {
        return http.post('front/enterprise/getElementList', data)
    },
    //  crm房屋操作列表
    userLog(data) {
        return http.get('front/enterpriseVtwo/userLog?staffInfoId=' + data.staffInfoId + '&limit=' + data.limit + '&page=' + data.page,)
    },
    //  crm房屋操作列表
    yixinList(data) {
        return http.get('front/ehome/lst?staffInfoId=' + data.staffInfoId + '&limit=' + data.limit + '&page=' + data.page,)
    },
    //  绑定酷家乐账号 =》搜索账号
    kjl_user_search(data) {
        return http.get('front/enterprise/kjl_user_search?searchValue=' + data)
    },
    // 绑定酷家乐账号 =》确认绑定
    kjl_bingding(data) {
        let id = JSON.parse(sessionStorage.getItem("staff")).id
        return http.get('front/enterprise/kjl_bingding?uid=' + id + "&id=" + data)
    },
    //  名片  修改个人信息
    update_basic(data) {
        let id = JSON.parse(sessionStorage.getItem("staff")).id
        data.staff_info_id = id
        return http.post('front/enterprise/update_basic', data)
    },
    // 修改头像
    update_head(data) {
        let id = JSON.parse(sessionStorage.getItem("staff")).id
        data.staff_info_id = id
        return http.post('front/enterprise/update_head', data)
    },
    // 获取模板信息
    card_temp() {
        let id = JSON.parse(sessionStorage.getItem("staff")).id
        let staff_info_id = id
        return http.get('front/enterprise/card_temp?staff_info_id=' + staff_info_id)
    },
    // 修改模板信息
    update_temp(data) {
        let id = JSON.parse(sessionStorage.getItem("staff")).id
        data.staff_info_id = id
        return http.post('front/enterprise/update_temp', data)
    },
    // 获取城市信息
    getcityinfo(data = 0) {
        return http.get('front/enterprise/city?city_id=' + data)
    },
    // 添加地址
    address_edit(data) {
        delete data.details;
        let id = JSON.parse(sessionStorage.getItem("staff")).id
        data.staff_info_id = id
        return http.post('front/enterprise/address_edit', data)
    },
    // 删除地址
    address_del(data) {
        let id = JSON.parse(sessionStorage.getItem("staff")).id
        data.staff_info_id = id
        return http.get('front/enterprise/address_del?staff_info_id=' + data.staff_info_id + '&id=' + data.id)
    },
    // 更新身份信息
    updataInfo() {
        let id = localStorage.getItem("token")
        return http.get(`front/enterpriseVtwo/getStaffList?id=${id}`)
    }


}