const Route = [
    {
        path: '/case',
        name: 'case',
        component: () => import('../views/case/case.vue'),
        meta: {
            title: '方案和实景'
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('../views/accountnum/index.vue'),
        meta: {
            title: '账号管理'
        }
    },
    {
        path: '/renew',
        name: 'renew',
        component: () => import('../views/accountnum/renew.vue'),
        meta: {
            title: '账号续费'
        }
    },
    {
        path: '/renew_tow',
        name: 'renew_tow',
        component: () => import('../views/accountnum/renew_tow.vue'),
        meta: {
            title: '账号续费'
        }
    },
    {
        path: '/applicant',
        name: 'applicant',
        component: () => import('../views/accountnum/applicant.vue'),
        meta: {
            title: '申请账号'
        }
    },
    {
        path: '/staffManage',
        name: 'applicant',
        component: () => import('../views/staffManage/index.vue'),
        meta: {
            title: '员工管理',
        }
    },
    {
        path: '/addStaff',
        name: 'applicant',
        component: () => import('../views/staffManage/addStaff.vue'),
        meta: {
            title: '添加员工'
        }
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('../views/order/index.vue'),
        meta: {
            title: '订单查询'
        }
    },
    {
        path: '/indexPage_two',
        name: 'order',
        component: () => import('../views/newuserinfo/indexPage_two.vue'),
        meta: {
            title: '订单查询'
        }
    },
    {
        path: '/details',
        name: 'applicant',
        component: () => import('../views/staffManage/details.vue'),
        meta: {
            title: '添加员工'
        }
    },
     {
        path: '/binding',
        name: 'binding',
        component: () => import('../views/binding/index.vue'),
        meta: {
            title: '绑定账号'
        }
    },

]

export default Route