import store from '@/store';
import axios from 'axios'; // 引入axios
import { Toast } from 'vant'
import Vue from 'vue'
const request = axios

// request.defaults.baseURL = 'https://os.fookbaby.fooktech.cn/api/' // ! 测试服
request.defaults.baseURL = 'https://fb.eelian.com.cn/api' // ! 正式服

request.defaults.timeout = 15000;//响应时

request.interceptors.request.use(
    config => {
        if (store.state.checkToken && sessionStorage.getItem('staff')) {
            config.headers.Authorization = JSON.parse(sessionStorage.getItem('token'));
            // config.headers.shopid = JSON.parse(sessionStorage.getItem('staff')).shop_id;

        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
)

request.interceptors.response.use(function (response) {

    return response

}, function (err) {
    if (err.response && err.response.data && err.response.data.code === 400) {
        let a = Vue.prototype.$Confirm
        a({ title: err.response.data.msg })
    }
    return Promise.reject(err)
})

function Request(url, parmas, method) {
    return new Promise((resolve, reject) => {
        request({
            method: method,
            url: url,
            data: parmas,
            headers: {
                // ! fuke => 测试
                // company: 'fuke',
                // ! elian => 正式
                company: 'elian',
                shopid: sessionStorage.getItem('staff') != null ? JSON.parse(sessionStorage.getItem('staff')).shop_id : ''
            }
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            // 方案接口 不提示错误
            var pattern = "front/enterpriseVtwo/designList";
            let data = url.startsWith(pattern)
            if (err.response.status !== 301) {
                if (err.response.data.msg && sessionStorage.getItem('staff') && !data) Toast.fail(err.response.data.msg)
                if (err.response.status == 500) Toast.fail('服务器错误！')
            }
            reject(err)
        })
    })
}



const get = (url, parmas) => {
    return Request(url, parmas, "get")
}
const post = (url, parmas) => {
    return Request(url, parmas, "post")
}

export default {
    get,
    post
}
