export default {
    handlePopstateRoute(routeParams,data,type) {
        window.addEventListener('popstate', function (event) {
            // 在这里处理路由变化，可以使用 routeParams 参数
            // 例如，你可以根据不同的路由参数执行不同的操作
            console.log('路由参数:', routeParams);
            data.push({ name: routeParams , params: { userid: sessionStorage.getItem('userid'),type} })
        });
    }
}
