import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api/shopping'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    checkToken: sessionStorage.getItem('token') ? true : false,
    code: '',
    // 员工列表item
    Data: {},
    // *****************ecrm********************** 首页
    parm: {
      shopId: 65,
      stffInfoId: 430,
      searchTimeType: 3,
      searchTime: '',
      staffStatus: '1',
      staffIdArr: [],
    },
    // 发送数据埋点
    createStatisticsLog: {
      staff_info_id: 0, //员工id
      relation_name: '', // 商品名称
      relation_id: 0, //商品id
      user_id: 0, //用户id
      user_name: '', //用户名
      //数据类型   1 访问商城首页  2访问商品 3用户转发商品 4 员工转发商品 5 访问名片 6 用户分享名片7 员工分享名片 8 访问视频 9 用户分享视频 10 员工分享视频 
      // 11 访问方案 12 用户分享方案 13 员工分享方案  14 访问实景图 15 用户分享实景图 16 员工分享实景图  17 访问微单页 18 用户分享微单页 19 员工分享微单页
      log_type: 0,
      stop_time: 0,//停留时间
      is_staff: 1, //是否是员工
      come_type: 2, //来源 1商城 2企微
    },


    // ***********客户详情类************
    // 获取房屋节点处理人列表
    get_houselist: {
      processDefinitionId: '',
      taskDefinitionKey: '',
      mobile: '',
      searchName:''
      // searchName:''
    },
    // 房屋下一步列表
    house_next: {
      id:'',
      houseCode:'',
      mobile: '',
      searchName:''
    },
    // 房屋状态列表
    houseStatusList:[],
    // ecrm 客户自定义 数组
    Ecrm_client:[]

  },
  getters: {
    getdata(state) {
      return state.Data
    },
    getparm(state) {
      return state.parm
    },
    getEcrm_client(state){
      return state.Ecrm_client

    }
  },
  mutations: {
    setData(state, data) {
      state.Data = data
    },
    setparm(state, data) {
      state.parm = data
    },
    updateValue(state, newValue) {
      state.parm = { ...state.parm, ...newValue }
    },
    // 数据埋点 修改id等
    updatacreateStatisticsLog(state, data) {
      state.createStatisticsLog = { ...state.createStatisticsLog, ...data }
    },
    //房屋节点处理人列表
    set_houselist(state, data, houseCode) {
      state.get_houselist = { ...state.get_houselist, ...data }

    },
    // 房屋下一步列表
    sethouse_next(state, data) {
      state.house_next = {...state.house_next,...data}
    },
    get_houseStatusList(state,data){
      state.houseStatusList = data
    },
    setEcrm_client(state, data) {
      state.Ecrm_client = data
    },

  },
  actions: {
    // 数据埋点
    async setcreateStatisticsLog({ state }, data) {
      try {
        const res = await api.createStatisticsLog(data);
        console.log('请求成功', data);
      } catch (error) {
        console.error('请求失败', data);
      }
    }
  },
  modules: {
  }
})
