import Vue from 'vue'
import VueRouter from 'vue-router'
import Route from './case'

Vue.use(VueRouter)

const routes = [
  ...Route,
  {
    path: '/goodlist',
    name: 'goodlist',
    component: () => import('../views/shopping/goodlist/goodlist.vue'),
    meta: {
      title: '商品列表'
    }
  },
  {
    path: '/card',
    name: 'card',
    component: () => import('../views/shopping/card/canvas.vue')
  },
  {
    path: '/myCard',
    name: 'myCard',
    component: () => import('../views/shopping/card/myCard.vue')
  },

  {
    path: '/material',
    name: 'material',
    component: () => import('../views/shopping/material/index.vue'),
    meta: {
      title: '素材'
    }
  },
  {
    path: '/qycrm',
    name: 'qycrm',
    component: () => import('../views/qycrm'),
    meta: {
      title: '添加用户',
      keepAlive: true
    }
  },
  {
    path: '/setLabel',
    name: 'setLabel',
    component: () => import('../views/qycrm/setLabel'),
    meta: {
      title: '设置标签'
    }
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: () => import('../views/qycrm/userinfo'),
    meta: {
      title: '用户信息'
    }
  },
  {
    path: '/addhouse',
    name: 'addhouse',
    component: () => import('../views/qycrm/addHouse'),
    meta: {
      title: '添加房屋'
    }
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('../views/Merchant'),
    meta: {
      title: '选择身份'
    }
  },

  {
    path: '/search',
    name: 'search',
    component: () => import('../views/case/search.vue'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/cript',
    name: 'cript',
    component: () => import('../views/cript'),
    meta: {
      title: '话术回复'
    }
  },
  {
    path: '/detailed',
    name: 'detailed',
    component: () => import('../views/cript/scriptdateiled.vue'),
    meta: {
      title: '话术详情'
    }
  },
  {
    path: '/quick',
    name: 'quick',
    component: () => import('../views/cript/quickReply.vue'),
    meta: {
      title: '话术列表'
    }
  },
  {
    path: '/addinfo',
    name: 'addinfo',
    component: () => import('../views/cript/addInfo.vue'),
    meta: {
      title: '编辑话术'
    }
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('../views/cript/groups.vue'),
    meta: {
      title: '选择分组'
    }
  },
  {
    path: '/edit',
    name: 'edit',
    component: () => import('../views/cript/editGroups.vue'),
    meta: {
      title: '编辑分组'
    }
  },
  {
    path: '/userinfos',
    name: 'userinfos',
    component: () => import('../views/newuserinfo/userInfo.vue'),
    meta: {
      title: '用户详情'
    }
  },
  {
    path: '/addUser',
    name: 'addUser',
    component: () => import('../views/newuserinfo/addUser.vue'),
    meta: {
      title: '添加用户'
    }
  },
  {
    path: '/house',
    name: 'house',
    component: () => import('../views/newuserinfo/addHouse.vue'),
    meta: {
      title: '添加房屋'
    }
  },
  {
    path: '/tagList',
    name: 'tagList',
    component: () => import('../views/newuserinfo/tagList.vue'),
    meta: {
      title: '设置标签'
    }
  },
  {
    path: '/follow',
    name: 'follow',
    component: () => import('../views/newuserinfo/addFollow.vue'),
    meta: {
      title: '添加跟进'
    }
  },
  {
    path: '/collection',
    name: 'collection',
    component: () => import('../views/newuserinfo/Collection.vue'),
    meta: {
      title: '回款'
    }
  },
  // 客户详情处添加合同
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/newuserinfo/Agreement.vue'),
    meta: {
      title: '签订合同'
    }
  },
  // 房屋操作处 添加合同
  {
    path: '/AgreementTwo',
    name: 'AgreementTwo',
    component: () => import('../views/newuserinfo/AgreementTwo.vue'),
    meta: {
      title: '签订合同'
    }
  },
  {
    path: '/houseDetailed',
    name: 'houseDetailed',
    component: () => import('../views/newuserinfo/houseDetailed.vue'),
    meta: {
      title: '房屋详情'
    }
  },
  {
    path: '/next',
    name: 'next',
    component: () => import('../views/newuserinfo/Next.vue'),
    meta: {
      title: '下一步'
    }
  },
  {
    path: '/moneyDetailed',
    name: 'moneyDetailed',
    component: () => import('../views/newuserinfo/moneyDetailed.vue'),
    meta: {
      title: '款项明细'
    }
  },
  {
    path: '/clueDeatiled',
    name: 'clueDeatiled',
    component: () => import('../views/newuserinfo/clueDetailed.vue'),
    meta: {
      title: '线索详情'
    }
  },
  {
    path: '/clueFollow',
    name: 'clueFollow',
    component: () => import('../views/newuserinfo/clueFollow.vue'),
    meta: {
      title: '线索跟进'
    }
  },
  {
    path: '/crmIndex',
    name: 'crm',
    component: () => import('../views/newuserinfo/crmIndex.vue'),
    meta: {
      title: 'crm系统'
    }
  },

  {
    path: '/newuserinfo',
    name: 'newuserinfo',
    component: () => import('../views/newuserinfo/index.vue'),
    meta: {
      title: '首页',
      keepAlive:true
    }
  },
  {
    path: '/demo',
    name: 'newuserinfo',
    component: () => import('../views/newuserinfo/programme.vue'),
    meta: {
      title: '方案'
    }
  },
  {
    path: '/addstaffLog',
    name: 'addstaffLog',
    component: () => import('../views/newuserinfo/addStaffLog.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/log',
    name: 'log',
    component: () => import('../views/newuserinfo/logDetail.vue'),
    meta: {
      title: '个人代办详情'
    }
  },
  {
    path: '/job',
    name: 'job',
    component: () => import('../views/newuserinfo/job.vue'),
    meta: {
      title: '代办列表'
    }
  },
  // {
  //   path: '/range',
  //   name: 'range',
  //   component: () => import('../views/newuserinfo/range.vue'),
  //   meta: {
  //     title: '大区筛选'
  //   }
  // },

]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // alert(to.name = 'merchant',from.name='newuserinfo')
  // if (to.name = 'merchant',from.name='newuserinfo') {
  //   alert(1)
  //   window.close()
  // }

  next()
});
export default router
